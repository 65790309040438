export const environment = {
    token: 'U2FsdGVkX19Hj+4KDNHgzY304B3BIfQTQNm8C8zv2MPb5e1FgJNBKf6mjzHB8mnc',
    vendorsMethod: 'U2FsdGVkX19dsTl4pXZeIP+UKWmJngoePiWD2vOSNMU=',
    getZoneMethod: 'U2FsdGVkX18O9p3jmsVzR1BOUtNn8M46KdR3TXigC1U=',
    platform: 'U2FsdGVkX18IAt3HeWLt/FLQXKERQ+tiQ+LWY0QFULM=',
    urlApi: 'U2FsdGVkX1/5U9Iz1BMfNLCg00XSq9T8sAP8tPa8FvGCH17ypcxB4l1cnQ7I+1uRHTfGq8WwsxAYmOFUlr2Q4Q==',
    key: 'T0rNaDoK4tr1Na?RTgcNmhKU=',
    secretKey: "YourSecretKeyForEncryption&Descryption",
    dbfull: "U2FsdGVkX1+FZULOfoT/mYnwBLaTWJc8fMkEJtlHCt8aGApKOkcIWFPFY3zyzD1TtvVIRmBFg8fTpJ+v+KzXrQ==",
    dbfullUrl: 'U2FsdGVkX18KqY2Da9HrK7WBA/P5mRZqEMvfVy1dpDZUJg6IWWN+27N7pxT1+JwK2kh3Wq3vC/LTi6ir1a7O1A==',
    tokenDbfull: 'YQ3F2RqZmAeXrYsY3xOd9cdFNOUgR798CRAJdDkWViCsrLctWhINn-p1Lv6BK1zvkhaT-NKzGL04JvXlfvEd3xfnjpvU1LDIefOir1fzyY7_IKbUEENElcbxQL6rrKFmCtW2HSHevbx-HVmrIS6asVk1RX3QK9EQuJ7GyfcfkClakoWjkMtd',
    db: 'U2FsdGVkX19gf0Rf6JLBHx/Rns3yLEYXyZESv+LrDygMq+HtsvdksZxJLP3EAF5x',
    dbSae: "U2FsdGVkX1+KihihY2v+voHASlZIpaEf+ADVsLjHYDIRnw1ceiuKF05L4TuFDseP",
    table: 'U2FsdGVkX1+YW18+H79r/MC5KAHrzgPUEoK27cbCqmI7HDMQ9oGxt6uz5Co3bf3j',
    authdbFUll: 'Basic ' + btoa('Onur:L4V1d43NsuPl3N1tud**=BghYjLaQeTB'),


    // dbfull
    TokenAuthPlataform: 'YQ3F2RqZmAeXrYsY3xOd9cdFNOUgR798CRAJdDkWViCsrLctWhINn-p1Lv6BK1zvkhaT-NKzGL04JvXlfvEd3xfnjpvU1LDIefOir1fzyY7_IKbUEENElcbxQL6rrKFmCtW2HSHevbx-HVmrIS6asVk1RX3QK9EQuJ7GyfcfkClakoWjkMtd',
};