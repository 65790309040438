import React, { Component } from "react";
import './index.css'

export class Loader extends Component<{}, {sendingData: boolean}> {
    render(){
        return(
            <div className="spinner-container">
                <div className="spinner"></div>
            </div>
        )
    }
}