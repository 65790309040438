function ExtractParams(url: string) {
    let params: any = {};

    if(typeof url === "string") {
        let index = url.indexOf("?");

        if(index !== -1) {
            url = url.slice(index+1);
            
            let textParams = url.split("&");
            textParams.forEach(param => {
                let name: string;
                let value: string = "";

                index = param.indexOf("=");

                if(index !== -1) {
                    let split = param.split("=");

                    name = decodeURI(split[0]);
                    value = decodeURI(split[1]);
                }
                else {
                    name = param;
                }

                params[name] = value;
            })
        }
    }

    return params;
}

export default ExtractParams;