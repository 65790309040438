import React from 'react';
import './App.css';

// import FormGenerator from './Form.Generator/Form.Generator';
import FormStruct from './Form.Struct/Form.Struct';
import json from "./JSON/index.json";
import { DecryptService } from './Service/encrypt.service';

import axios from "axios"
// import FormGenerator from './Form.Generator/Form.Generator';
import ExtractParams from './LIB/url';
import { environment } from './environments/enviroment.prod';
// import ExtractParams from './LIB/url';
import { UbicacionesService } from "./Service/ubicaciones.service"
import { ValuesService } from './Service/values.service';


const params = ExtractParams(document.location.href);

async function SendData(data: Array<any>, instance: FormStruct) {
  instance.renderExtern = undefined;
  instance.pageLocal.title = "Enviado";
  instance.pageLocal.subtitle = "Eso ha sido todo. Ya puedes cerrar esta página";
  instance.forceUpdate();

  let keyCiudad = data.find(item => item.key === "CIUDAD");
  
  if(keyCiudad) {
    let cityData = await UbicacionesService.GetOnlyCiudad(keyCiudad.value);
    let stateData = await UbicacionesService.GetEstado(cityData?.id_ciudad || "");
    
    data.push({key: "ESTADO", value: stateData?.nombre_esta})
    keyCiudad.value = cityData?.nombre_ciudad;
  }
  
  let keyMunicipio = data.find(item => item.key === "MUNICIPIO");

  if(keyMunicipio) {
    keyMunicipio.value = (await UbicacionesService.GetOnlyMunicipio(keyMunicipio.value))?.nombre_mun;
  }

  let keySector = data.find(item => item.key === "SECTOR");

  if(keySector) {
    keySector.value = (await UbicacionesService.GetOnlySector(keySector.value))?.nombre_sector;
  }

  let keyUbicacion = data.find(item => item.key === "ZONA");

  if(keyUbicacion) {
    keyUbicacion.value = (await UbicacionesService.GetOnlyUbicacionZona(keyUbicacion.value))?.nombre_ubicacion_zona;
  }
  
  // data.push({key:"EMAIL", value: params.email})
  // data.push({key:"PASS", value: params.pass})

  // console.log("SOY DATA: ", data)
  
  const keyAccess = [
    "CIUDAD",
    "ESTADO",
    "MUNICIPIO",
    "SECTOR",
    "ZONA"
  ];

  var direccionFiscal = "";

  for(var i of keyAccess) {
    var obj = data.find(item => item.key === i);

    if(obj) {
      direccionFiscal += (direccionFiscal ? " " : "") + obj.value;
    }
  }


  let newData : {[key: string]: string} = {}  

  let campo, valor

  for (let index = 0; index < data.length; index++) {
    for (var key in data[index]) {
      if (key === "key") { campo = data[index][key] }
      if (key === "value") { valor = data[index][key] }
    }
    newData[campo] = valor
  }

  

  const dataBody = {
    IdRegistro: "",
    Vendedor: newData.VENDEDOR || "N/A",
    correo: ValuesService.values["EMAIL"],
    FechaVisita: newData.FECHA_VISITA,
    NombreCliente: newData.NOMBRE_APELLIDO || "N/A",
    PhoneCliente: newData.NUMERO_CELULAR || "N/A",
    InteresEnContratar: newData.INTERES_CONTRATAR || "N/A",
    PoseeServicioInternet: newData.SERVICIO_INTERNET || "N/A",
    ProvedorInternet: newData.PROVEEDOR_INTERNET || "N/A",
    ContactoEfectivo: newData.CONTACTO_EFECTIVO || "N/A",
    NoContactoEfectivo: newData.MOTIVO_NOCONTACTO || "N/A",
    tipoResidencia: newData.TIPO_RESIDENCIA || "N/A",
    NombreResidencia: newData.NOMBRE_EDIFICIO || "N/A",
    Zona: newData.ZONA || "N/A",
    Ciudad: newData.CIUDAD || "N/A",
    DireccionFiscal: direccionFiscal || "N/A",
  }

  // console.log
  // console.log("____>>>>", dataBody)

  request(dataBody)
  return true
}

function request(DataBody:object){
  let apiUrl = DecryptService.decrypt(environment.dbfullUrl, 16, 'default');

  var config = {
    method: 'post',
    url: `${apiUrl}`,
    headers: {
      'TokenAuthPlataform': `${environment.tokenDbfull}`,
      'db' : `${environment.db}`,
      'table': `${environment.table}`,
      'Authorization': `${environment.authdbFUll}`,
      'x-keys-to-add-id': '["IdRegistro"]', 
      'x-keys-of-arrays': '[]', 
      'x-relations': 'false', 
      'Content-Type': 'application/json'
    },
    data : DataBody
  };
  

  axios(config)
  .then(function (response:any) {
    // console.log(JSON.stringify(response.data));
    
  })
  .catch(function (error:any) {
    console.error(error);
  });
}


function App() {
  return (
    // <div>H</div>
    <div className='app'>
      {/* <FormGenerator /> */}
      <FormStruct template={json as any} onFinish={SendData} />
    </div>

  );
}

export default App;
