import React from "react";
import GenerateElement from "./Element";
import "./Form.Page.css";
import "./Form.Effect.css";
import { PropsElement, PropsFormPage } from "../props";
import SetValueToAll from "./set.value";
import { ValuesService } from "../Service/values.service";
import { Loader } from '../Elements/Loader/index';

/*
    Estructura de una página de formulario

    * title
    * subtitle
    * type
    * name
    * background
    * color
 */


// Renderizar Titulo de la página
function TitleComponent(props: PropsFormPage) : JSX.Element | null {
    if(typeof props.title === "string") {
        return (
            <div className="FM-form-page-title">{props.title}</div>
        )
    }
    return null;
}

// Renderizar Subtitulo de la página
function SubtitleComponent(props: PropsFormPage) : JSX.Element | null {
    if(typeof props.subtitle === "string") {
        return <div className="FM-form-page-subtitle">{props.subtitle}</div>
    }
    return null;
}

interface ErrorProps {
    msg: string
}

// Renderizar Error de planilla
function ErrorComponent(props: ErrorProps) : JSX.Element {
    return (
        <div className="FM-form-error">{props.msg}</div>
    )
}

function ButtonBottom({next, back, onBack, parent}: PropsFormPage) : JSX.Element | null {
    let main: Array<PropsElement> = [];

    if(back instanceof Object) {
        back.type = "button";
        back.onClick = onBack;
        back.align = "left";

        main.push(back);
    }
    else if(typeof back === "string") {
        main.push({
            type: "button", 
            display: back,
            align: "left",
            onClick: onBack,
            parent: parent
        })
    }

    if(next instanceof Object) {
        next.type = "submit";
        next.align = "right";
        main.push(next);
    }
    if(typeof next === "string") {
        main.push({
            type: "submit", 
            display: next,
            align: "right",
            parent: parent
        })
    }

    if(main.length > 0) {
        let group = {type:"group", main, parent};
        
        return <GenerateElement {...group} parent={group} />;
    }
    return null;
}

interface PropsFormPageExtended extends PropsFormPage {
    initValues?: Array<any> | PropsFormPage
    render?: any
}

// Renderizar Página de formulario
export default class FormPage extends React.Component<PropsFormPageExtended> {
    stateElements: any = {}
    effect: string | undefined;
    error: string | null = null;
    
    constructor(props: PropsFormPageExtended) {
        super(props.initValues !== undefined ? SetValueToAll(props, props.initValues) : props);

        this.VerifyError = this.VerifyError.bind(this);
        this.HandlerOnChange = this.HandlerOnChange.bind(this);
        this.HandlerOnSubmit = this.HandlerOnSubmit.bind(this);
    }

    VerifyError() {
        let msgError: string | null = null;

        for(let msg in this.stateElements) {
            if(typeof this.stateElements[msg] === "string") {
                msgError = this.stateElements[msg];
                break;
            }
        }

        const ExplorerAll = (element: any): any => {
            if(element instanceof Object) {
                if(element.type === "group") return ExplorerAll(element.main);
                else {
                    if(element.required && (this.stateElements[element.name] === undefined && !this.props.value)) {
                        msgError = "Algunos campos son requeridos.";
                    }
                }
            }
            else if(element instanceof Array) {
                element.forEach((e: any) => ExplorerAll(e));
            }
        }

        ExplorerAll(this.props);

        return msgError;
    }

    HandlerOnSubmit(event:React.FormEvent<HTMLFormElement>) {
        event.preventDefault();
        this.error = this.VerifyError();

        if(this.error) {
            this.forceUpdate();
            return;
        }
        
        if(typeof this.props.effect === "string") {
            this.effect = this.props.effect as string;
            this.forceUpdate();
            setTimeout(
                () => {if(typeof this.props.onNext === "function") this.props.onNext();}, 500
            )
        }
        else {
            if(typeof this.props.onNext === "function") this.props.onNext();
        }
    }

    HandlerOnChange(value: any, key: string, error?: string) {
        this.stateElements[key] = error ? error : null;
        if(this.props.onChange) this.props.onChange(value, key, error);
    }
    
    render() {
        // let {title, subtitle, back, next, onNext, onBack, ...template} = this.props; // se extraen los parametros no necesarios
    
        let render: JSX.Element;
        let color: string | undefined;
    
        // if(props instanceof Object) {
    
            color = this.props.color;
            
            // console.log(next)
            
            // La planilla es válida

            // if(template.name == "FECHA_VISITA") {
            //     if(next instanceof Object) next.disabled = 
            // }
            
            render = (
                <>
                    <TitleComponent title={this.props.title} parent={this.props.parent} />
                    <SubtitleComponent subtitle={this.props.subtitle} parent={this.props.parent} />
                    <div className="FM-form-page-container">
                        <>
                            <GenerateElement {...this.props} onChange={this.HandlerOnChange} parent={this.props.parent} />
                            {this.props.render}
                        </>
                    </div>
                    {this.error && <ErrorComponent msg={this.error} />}
                    <ButtonBottom onBack={this.props.onBack} back={this.props.back} next={this.props.next} parent={this.props.parent} />
        
                </>
            )
        // }
        // else {
        //     // OOOOPS! la planilla es invalida
        //     render = <ErrorComponent msg="PLANILLA INVALIDA!" />
        // }
    
        let className = "FM-form-page";
    
        if(typeof this.props.effect === "string") {
            className += " FM-form-effect";
        }
        if(typeof this.effect === "string") {
            className += " FM-effect--" + this.effect;
        }
    
        // Renderizado!
        return (
            <form onSubmit={this.HandlerOnSubmit} className={className} style={{"--color": color} as any}>
                {render}
            </form>
            
        )
    }
}