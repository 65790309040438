// Importaciones Externas
import React from "react";
import MuiButton from "@mui/material/Button";
import { PropsElement } from "../../props";

// Componente de tipo Boton
class Button extends React.Component<PropsElement> {
    render() {
        let props = this.props;

        // Prepara una estructura con los estilos del elemento a partir del color resivido
        let style = {backgroundColor: "var(--color)"};
    
        // Renderizamos el elemento
        return (
            <MuiButton
                type={props.type === "submit" ? "submit" : "button"}
                variant="contained"
                href={props.href}
                disabled={props.disabled}
                autoFocus={props.focus}
                className={props.className}
                id={props.id}
                onClick={props.onClick}
                // type={props.type}
                style={style}
            >
                {props.display}
            </MuiButton>
        )
    }
}

export default Button; // Exportacion