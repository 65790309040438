import React from "react";

import "./Element.css";

import Input from "../Elements/Input";
import ButtonElement from "../Elements/Button";
import Checkbox from "../Elements/Checkbox"
import Phone from "../Elements/Phone"
import Radio from "../Elements/Radio"
import Rating from "../Elements/Rating"
import Select from "../Elements/Select"
import Slider from "../Elements/Slider"
import Switch from "../Elements/Switch"
import Group from "../Elements/Group"
import { PropsElement } from "../props";
import CitesComponent from "../Elements/Cites";
import axios from "axios";
import { DecryptService } from "../Service/encrypt.service";
import ExtractParams from "../LIB/url";
import { ValuesService } from "../Service/values.service";
import { UbicacionesService, API_UBICACIONES, API_ZONAS, API_MUNICIPIO, API_SECTOR, API_CIUDAD, API_UBICACION_ZONA } from "../Service/ubicaciones.service";

interface PropsElementTemplate extends PropsElement {
    render: Function
};

var number = 0;

var haveZones: boolean;

// let result: any = undefined
// axios.get(`https://api.thomas-talk.me/sae/GetDataZonas`)
//         .then(res => {
//             result = res.data
//             console.log(result)
//         })
//         .catch((e) => {
//             console.error(e);
//     });

function loadMetadata(text: string, name: string) {
    let metadata: Array<any> = [];

    try {
        let loaded_metadata = require("../JSON/" + text + ".json")
        
        // console.log("Metadata cargada correctamente!");
        metadata = loaded_metadata;
    }
    catch(e: any) {
        // console.warn(e)
    }

    return metadata;
}

class Element extends React.Component<PropsElementTemplate> {
    ref: React.RefObject<any> = React.createRef()
    state: Readonly<{
        dataVendedor: {value: string}[],
        metadata: any,
        loaded: boolean
    }>;
    optionElements: Array<JSX.Element> = [];
    newSeller!: string;
    haveZones!: boolean;

    constructor(props: PropsElementTemplate) {
        super(props)
        this.state = {
            // dataUbicaciones: {},
            dataVendedor: [{value: ""}],
            metadata: {
                options: []
            },
            // requestDone: false,
            loaded: false
        }
        // this.awaitResult();
            // .then(this.MapData.bind(this))
        
            sessionStorage.setItem('vend', 'U2FsdGVkX19ckVCNQor78Sabi6hQJZlT1EmZwwCituMUVqOrw0qmOLFfoPkjBr/SH+osLjAkJ2upn1/uSLfSDAHEKH4wfPSHEO/mQMnHw125tzdeXthGPAe/LfgmIx+1Wt3a9+mmxZbyrx1RhPmvYMmuYKZROnuGFcZ4BNkBFwS4mEZXw91LaOZ9GxnBmwdrxYWi9FI1KQJbtT9H6LQi6UiTpxDg8W+0oBscgoQ/zSkhSx+iAGyv4RFNmL5gOr0DUzjUw3NcExEcfmOLGXI8/J8RXzKbnCZuSA+xM9UQ5icDlprGYNr6VYgDOEwz5myz');
        
        let dataSession = ExtractParams(document.location.href);
        let seller = dataSession.session.replace('"','').trim();
        let newSeller = JSON.parse(DecryptService.decryptSession(seller))

        ValuesService.values["EMAIL"] = newSeller.email;
        this.newSeller = `${newSeller.name} ${newSeller.lastname}`
        
        this.HandlerOnChange = this.HandlerOnChange.bind(this)
    }

    // async awaitResult() {
    //     // do {
    //     //     await new Promise((resolve) => setTimeout(resolve, 200))
    //     // } while (UbicacionesService.value === undefined);
    //     // this.forceUpdate();
    // } 

    LoadRequest(name: string): boolean {
        
        if (/* !UbicacionesService.value || */ this.state.loaded) return true;

        let metadata: any = {};
        switch(name) {
            case "CIUDAD": {
                UbicacionesService.GetCiudades()
                    .then(ciudades => {
                        number++;
        
                        if(number >= 20) throw(new Error("OK"))
                        
                        metadata.options = ciudades.map((element: API_CIUDAD) => ({display: element.nombre_ciudad ,value: element.id_ciudad}));
                        this.setState({metadata});
                    })
            } break;

            case "MUNICIPIO": {
                UbicacionesService.GetEstado(ValuesService.values['CIUDAD'])
                    .then(estado => {
                        const idEstado = estado!.id_esta;

                        UbicacionesService.GetMunicipios(idEstado)
                            .then(municipios => {
                                metadata.options = municipios.map((element: API_MUNICIPIO) => ({display: element.nombre_mun ,value: element.id_mun}));
                                this.setState({metadata});
                            })
                        })
                    } break;
                    
            case "SECTOR": {
                const idMunicipio = ValuesService.values['MUNICIPIO'] as string;

                UbicacionesService.GetSectores(idMunicipio)
                    .then(sectores => {
                        metadata.options = sectores.map((element: API_SECTOR) => ({display: element.nombre_sector ,value: element.id_sector}));
                        this.setState({metadata});
                    })
                    
                } break;
                
            case "ZONA": 
                const idSector = ValuesService.values['SECTOR'] as string;
                
                UbicacionesService.GetUbicacionesZonas(idSector)
                    .then(ubicacionesZonas => {
                        metadata.options = ubicacionesZonas.map((element: API_UBICACION_ZONA) => ({display: element.nombre_ubicacion_zona ,value: element.id_ubi}));
                        this.setState({metadata});
                    })

                // console.log("---->", metadata.options);
                break;

            case "VENDEDOR":
                metadata.options = [{display: this.newSeller, value: this.newSeller}]
                this.setState({metadata});
                break;

            // case "ZONA":
            //     // Esto es MUUUUUY ineficiente
            //     const zonas = UbicacionesService.sectores
            //         .filter((sector: API_SECTOR) => sector.id_ciudad === idCiudad_z) // filtrar sectores de la ciudad seleccionada
            //         .map((sector: API_SECTOR) => sector.id_zona) // obtener las zonas de cada sector
            //         .filter((item: string, index: number, arr: string[]) => arr.indexOf(item) === index) // eliminar duplicados
            //         .map((id: string) => UbicacionesService.zonas.find((zona: API_ZONA) => zona.id_zona === id)) // finalmente, obtener las zonas
            //         .filter((item: API_ZONAS | undefined) => item !== undefined) as API_ZONAS[];
            //     console.log(zonas);

            //     if(zonas.length > 0) {
            //         metadata.options = zonas.map((z: API_ZONA) => ({display: z.nombre_zona, value: z.id_zona}));
            //     }else {
            //         metadata.options = [{display: 'No posee zonas', value: 'No posee zonas'}];
            //         // console.log(this.props.parent);
            //         (this.props.parent! as any).next = {disabled: true, display: 'Siguiente'};

            //         if(this.props.forceUpdate) this.props.forceUpdate();
            //     }
            //     break;
                default:
                    return false;
        }

        this.setState({ loaded: true })
        
        return true;
    }

    // MapData(data: API_UBICACIONES) {
    //     this.setState({
    //         dataUbicaciones: data,
    //         requestDone: true
    //     });
    // }
    
    HandlerOnChange(value: any, nameOrError: any, error: string) {
        if(typeof this.props.onChange === "function") {
            if(this.props.type === "group") this.props.onChange(value, nameOrError, error);
            else {
                this.props.onChange(value, this.props.name, nameOrError);
            }
        }
    }

    componentDidUpdate(): void {
        if(this.props.focus) this.ref.current.focus()
        this.LoadRequest(this.props.name as string);
    }
    
    render() {
        let props = this.props;
    
        let className = "FM-element";
        let metadata = this.state.metadata;
    
        switch(props.align) {
            case "left":
            case "right":
            case "center":
                className += " FM-align-" + props.align;
                break;
            case "stretch":
            case undefined:
                // ALINEAMIENTO POR DEFECTO!
                break;
            default:
                //console.warn(`No existe el alineamiento "${props.align}"`);
                break;
        }

        if(!this.LoadRequest(props.name as string)) {
            // console.log(props.metadata)
            if(typeof props.metadata === "string") metadata = loadMetadata(props.metadata, props.name as string);
            else metadata = props.metadata;
            // if(
            //     typeof props.options === "string" && 
            //     metadata instanceof Array && 
            //     metadata[0] instanceof Object &&
            //     metadata[0].templateName !== undefined &&
            //     metadata[0].metadata !== undefined
            // ) {
            //     let index = metadata.findIndex((meta: any) => (meta.templateName === props.options))
    
            //     if(index !== -1) {
            //         metadata = {options: metadata[index].metadata}
            //     }
            // }
            if(metadata instanceof Array) {
                let index = metadata.findIndex((meta: any) => (meta.templateName === props.loadMetadata))
    
                if(index !== -1) {
                    const {templateName, ...metadata_load} = metadata[index];
                    metadata = metadata_load;
                }
                else metadata = {};
            }
            else if(!(metadata instanceof Object)) {
                metadata = {} // objeto vacio!
            }
        }
        
        return (
            <div className={className}>
                <div className="FM-element-container" ref={this.ref}>
                    <props.render {...props} onChange={this.HandlerOnChange} {...metadata} />
                </div>
            </div>
        )
    }
}

export default function GenerateElement(props: PropsElement) : JSX.Element | null {
    let Render: Function = () => {};

    switch(props.type) {
        case "input":
        case "date":
        case "time":
        case "number":
        case "money":
        case "email":
            Render = Input;
            break;
        case "button":
        case "submit":
            Render = ButtonElement;
            break;
        case "checkbox":
            Render = Checkbox;
            break;
        case "phone":
            Render = Phone;
            break;
        case "radio":
            Render = Radio;
            break;
        case "rating":
            Render = Rating;
            break;
        case "select":
            Render = Select;
            break;
        case "slider":
            Render = Slider;
            break;
        case "switch":
            Render = Switch;
            break;
        case "group":
            Render = Group;
            break;
        case "cites":
            Render = CitesComponent;
            break;

        case "none": // No renderizar nada!
            break;
            
        // en caso de no encontrar un tipo apropiado?
        default:
            console.warn(`No existe un elemento de tipo "${props.type}"`); // No renderizar nada!
            break;
        
    }
    return <Element {...props} render={Render} />
}