class values {
    public values: {[key: string]: any}
    
    constructor() {
        this.values = {};
    }
}

export const ValuesService = new values();

// export default ref;