import { environment } from "../environments/enviroment.prod";
var CryptoJS = require("crypto-js");

export class DecryptService {

  constructor() { }

      // !Desencriptar datos
    static decrypt(str: string, keySize: number, token: string) {
        let security = token == 'default' ? environment.key : token
        if ( typeof  str != "number" && str != '' && str != null && str != undefined) {
          return CryptoJS.AES.decrypt(str, security, {
            keySize: keySize,
            mode: CryptoJS.mode.ECB,
            padding: CryptoJS.pad.Pkcs7,
        }).toString(CryptoJS.enc.Utf8);
      }
    }

    static decryptSession(textToDecrypt: any) {
      try {
  
        if (textToDecrypt) {
          var _descrypt = CryptoJS.AES.decrypt(textToDecrypt, environment.secretKey.trim()).toString(CryptoJS.enc.Utf8)
          return _descrypt;
        } else {
          return textToDecrypt
        }
  
      } catch (error) {
        console.error(error)
        return textToDecrypt
      }
    }

    //Encripta la los datos
    public static encrypt(str: string){
      let encrypted = CryptoJS.AES.encrypt(str, environment.key, {
        keySize: 16,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encrypted.toString();
    }

    public static EncrypObj(obj: any) {
        const newObj: any = {};

        for(let keyName in obj) {
            if(obj[keyName] instanceof Array || obj[keyName] instanceof Object) {
                obj[keyName] = this.EncrypObj(obj[keyName]);
            }
            else newObj[keyName] = DecryptService.encrypt(String(obj[keyName]));
        }
        return newObj;
    }
    
    // Encripta los datos que que deben ser enviados en una peticion
    public static EncrypDataHash(Datos:any){
        return new Promise((resolve,reject)=>{
            try {
                resolve(DecryptService.EncrypObj(Datos));
            } catch (error) {
                reject(error);
            }
        })
      }
}