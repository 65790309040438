import { DecryptService } from "./encrypt.service";
import { environment } from "../environments/enviroment.prod";
import axios from "axios";

// INTERFACEZ **********************************************************

export interface API_CIUDAD {
  id_ciudad:      string,
  nombre_ciudad:  string,
  status_ciudad:  string,
  id_esta:        string,
  codigo_ciudad:  string
}

export interface API_ESTADO {
  id_esta:        string,
  nombre_esta:    string,
  status_esta:    string,
  id_pais:        string,
  codigo_esta:    string
}

export interface API_MUNICIPIO {
  id_mun:         string,
  id_esta:        string,
  nombre_mun:     string,
  status_mun:     string
}

export interface API_SECTOR {
  id_sector:          string
  id_zona:            string
  nombre_sector:      string    
  status_sector:      string    
  id_ciudad:          string
  id_mun:             string
  id_parroquia:       string    
}

export interface API_NOMENCLATURA {
  id_nomen:           string
  nombre_nomen:       string
  abrev_nomen:        string
  status_nomen:       string
}

export interface API_UBICACION {
  id_ubi:         string
  nombre_ubi:     string
  status_ubi:     string
  id_nomen:       string
}

export interface API_SECTORFRANQUICIA {
  id_franq: string,
  id_sector: string
}

export interface API_UBICACION_SECTOR {
  id_sector:  string
  id_ubi:     string
}

export interface API_EDIFICIO {
  id_edif: string
  id_sector: string
  nombre_edif: string
  status_edif: string
  urb: null | string
}

export interface API_FRANQUICIA {
  id_franq: string,
  nombre_franq: string,
  id_emp: string,
  obser_franq: string,
  direccion_franq: string,
  serie:string,
  id_gf:string,
  ord: number,
  c_sistema: boolean,
  serie_p: string,
  serie_pago: string,
  serie_factura: string,
  iniciales: string,
  codigo_legal: string,
  codigo_franq: string,
  logo_franq: string,
  serie_nro_cp: null,
  id_operador:string
}

export interface API_PROMOS {
  [key: string]: string | boolean | Array<string>
  name: string
  img: string
  description: string
  description2: string
  description3: string
  description4: string
  descriptionDB: string
  info: string
  ciudad: string
  TipoServ: string
  plan: string,
  vigencia: string,
  descript: boolean,
  id_det_orden: string,
  allProm: boolean,
  Tipo: string,
  Zonas: Array<string>
  Botones: Array<string>
}

export interface API_URBANIZACION {
  id_urb: string,
  id_sector: string,
  nombre_urb: string,
  status_urb: string
}

export interface API_ZONAS {
  id_zona: string,
  nombre_zona: string,
  status_zona: string
}

export interface API_UBICACIONES {
  DataCiudad: Array<API_CIUDAD>
  DataEstados: Array<API_ESTADO>
  DataSector: Array<API_SECTOR>
  DataMunicipio: Array<API_MUNICIPIO>
  DataNomenclatura: Array<API_NOMENCLATURA>
  DataUbicacionSector: Array<API_UBICACION_SECTOR>
  DataUbicacion: Array<API_UBICACION>
  DataSector_Franquicia: Array<API_SECTORFRANQUICIA>
  DataEdificios: Array<API_EDIFICIO>
  DataFranquicia: Array<API_FRANQUICIA>
  DataUrbanizacion: Array<API_URBANIZACION>
  DataZona: Array<API_ZONAS>
}

export interface API_UBICACION_ZONA extends API_UBICACION, API_NOMENCLATURA {
  nombre_ubicacion_zona: string
}



// SERVICE ************************************************************************

class UbicacionesClass {
  private ciudades: undefined | API_CIUDAD[];
  private estados: undefined | API_ESTADO[];
  private sectores: undefined | API_SECTOR[];
  private municipios: undefined | API_MUNICIPIO[];
  private edificios: undefined | API_EDIFICIO[];
  private franquicias: undefined | API_FRANQUICIA[];
  private nomenclaturas: undefined | API_NOMENCLATURA[];
  private zonas: undefined | API_ZONAS[];
  private urbanizaciones: undefined | API_URBANIZACION[];
  private ubicaciones: undefined | API_UBICACION[];
  private ubicaciones_sector: undefined | API_UBICACION_SECTOR[];
  private ubicaciones_zonas: undefined | API_UBICACION_ZONA[];
  // public value: API_UBICACIONES | undefined;

    constructor() {
    }

    ReduceMultiples<type>(arr: type[], callback:(v1: type, v2: type) => Boolean): type[] {
      var x = 0;
      var r;
      while(x < arr.length) {
        const munX = arr[x];
        r = false;

        for(let y = x+1; y < arr.length; y++) {
          const munY = arr[y];

          if(r = !callback(munX, munY)) break;
        }

        if(r) arr.splice(x, 1);
        else x++
      }

      return arr;
    }

    // public InitLocations(data: API_UBICACIONES) {
        

          // Eliminamos todas las ubicaciones que no concuerdan
          // this.estados = data.DataEstados = ReduceMultiples(
          //   data.DataEstados.filter(item => item.nombre_esta !== "UNICA" && item.nombre_esta !== "POR COLOCAR" && item.id_pais === "PA0001" && item.nombre_esta !== "VENEZUELA").map(item => ({...item, nombre_esta: item.nombre_esta.trim().toUpperCase()})),
          //   (v1, v2) => (v1.nombre_esta !== v2.nombre_esta) // PA0001 == venezuela
          // )
  
          // this.ciudades = data.DataCiudad = 

          // this.edificios = data.DataEdificios = data.DataEdificios
          //   .filter(item => item.nombre_edif !== "UNICA" && item.nombre_edif !== "POR COLOCAR")
          //   .map(item => ({...item, nombre_edif: item.nombre_edif.trim().toUpperCase()}))

          // this.franquicias = data.DataFranquicia = ReduceMultiples(
          //   data.DataFranquicia.filter(item => item.nombre_franq !== "UNICA" && item.nombre_franq !== "POR COLOCAR").map(item => ({...item, nombre_franq: item.nombre_franq.trim().toUpperCase()})),
          //   (v1, v2) => (v1.nombre_franq !== v2.nombre_franq || data.DataSector_Franquicia.find(i => i.id_franq === v1.id_franq)?.id_sector !== data.DataSector_Franquicia.find(i => i.id_franq === v2.id_franq)?.id_sector)
          // )

          // this.municipios = data.DataMunicipio = ReduceMultiples(
          //   data.DataMunicipio.filter(item => item.nombre_mun !== "UNICA" && item.nombre_mun !== "POR COLOCAR").map(item => ({...item, nombre_mun: item.nombre_mun.trim().toUpperCase()})),
          //   (v1, v2) => !(v1.nombre_mun === v2.nombre_mun && v1.id_esta === v2.id_esta)
          // )

          // this.nomenclaturas = data.DataNomenclatura = ReduceMultiples(
          //   data.DataNomenclatura.filter(item => item.nombre_nomen !== "UNICA" && item.nombre_nomen !== "POR COLOCAR").map(item => ({...item, nombre_nomen: item.nombre_nomen.trim().toUpperCase(), abrev_nomen: item.abrev_nomen.trim().toUpperCase()})),
          //   (v1, v2) => (v1.nombre_nomen !== v2.nombre_nomen)
          // )

          // this.sectores = data.DataSector = 

          // this.zonas = data.DataZona = ReduceMultiples(
          //   data.DataZona.filter(item => item.nombre_zona !== "" && item.nombre_zona !== "").map(item => ({...item, nombre_sector: item.nombre_zona.trim().toUpperCase(), nombre_zonas: item.nombre_zona.trim().toUpperCase()})),
          //   (v1, v2) => (v1.nombre_sector !== v2.nombre_sector || v1.id_zona !== v2.id_zona)
          // )

          // this.urbanizaciones = data.DataUrbanizacion = ReduceMultiples(
          //   data.DataUrbanizacion.filter(item => item.nombre_urb !== "UNICA" && item.nombre_urb !== "POR COLOCAR").map(item => ({...item, nombre_sector: item.nombre_urb.trim().toUpperCase()})),
          //   (v1, v2) => (v1.nombre_sector !== v2.nombre_sector || v1.nombre_urb !== v2.nombre_urb)
          // )

          // this.ubicaciones_zonas = this.sectores.reduce((total, sector) => total.concat(this.GetUbicacionesZonas(sector.id_sector)), [] as Array<API_UBICACION_ZONA>);
    // }

    async GetCiudades() {
      var ciudades: API_CIUDAD[] | undefined = this.ciudades;

      if(!ciudades) {
        var estados: API_ESTADO[] = await this.GetEstados();
        ciudades = await this.MasterGETDBFULL("ciudad");

  
        // filtrar lo ingenesario
        this.ciudades = ciudades = ciudades!
            .filter(item => item.nombre_ciudad !== "UNICA" && item.nombre_ciudad !== "POR COLOCAR" && estados.some(est => est.id_esta === item.id_esta))
            .map(item => ({...item, nombre_ciudad: item.nombre_ciudad.trim().toUpperCase()}))
      }

        return ciudades;
    }

    async GetOnlyCiudad(id_ciudad: string) {
      var ciudades: API_CIUDAD[] = await this.GetCiudades();

      return ciudades.find(city => city.id_ciudad === id_ciudad);
    }



    async GetEstados() {
      var estados: API_ESTADO[] | undefined = this.estados;

      if(!estados) {
        estados = await this.MasterGETDBFULL("estado");
        
        // filtrar lo ingenesario
        this.estados = estados = this.ReduceMultiples(
          estados!.filter(item => item.nombre_esta !== "UNICA" && item.nombre_esta !== "POR COLOCAR" && item.id_pais === "PA0001" && item.nombre_esta !== "VENEZUELA").map(item => ({...item, nombre_esta: item.nombre_esta.trim().toUpperCase()})),
          (v1, v2) => (v1.nombre_esta !== v2.nombre_esta) // PA0001 == venezuela
          )
        }      
        
        return estados;
      }
      
      async GetEstado(id_ciudad: string) {
        var ciudad = await this.GetOnlyCiudad(id_ciudad);
        var estados = await this.GetEstados();
        
        return estados.find(state => ciudad?.id_esta === state.id_esta);
      }
      
      async GetOnlyEstado(id_estado: string) {
        var estados = await this.GetEstados();
      return estados.find(state => state.id_esta === id_estado);
    }



    async GetMunicipios(id_estado?: string) {
      var municipios: API_MUNICIPIO[] | undefined = this.municipios;
      
      if(!municipios) {
        municipios = await this.MasterGETDBFULL("municipio");

        this.municipios = municipios = this.ReduceMultiples(
          municipios!.filter(item => item.nombre_mun !== "UNICA" && item.nombre_mun !== "POR COLOCAR").map(item => ({...item, nombre_mun: item.nombre_mun.trim().toUpperCase()})),
          (v1, v2) => !(v1.nombre_mun === v2.nombre_mun && v1.id_esta === v2.id_esta)
        )
      }

      return id_estado ? municipios.filter(mun => mun.id_esta === id_estado) : municipios;
    }

    async GetOnlyMunicipio(id_municipio: string) {
      var municipios = await this.GetMunicipios()
      return municipios.find(municipality => municipality.id_mun === id_municipio);
    }



    async GetSectores(id_municipio?: string) {
      var sectores: API_SECTOR[] | undefined = this.sectores;

      if(!sectores) {
        sectores = await this.MasterGETDBFULL("sector");

        this.sectores = sectores = this.ReduceMultiples(
          sectores!.filter(item => item.nombre_sector !== "" && item.nombre_sector !== "").map(item => ({...item, nombre_sector: item.nombre_sector.trim().toUpperCase()})),
          (v1, v2) => (v1.nombre_sector !== v2.nombre_sector || v1.id_ciudad !== v2.id_ciudad)
        )
      }

      return id_municipio ? sectores.filter(sector => sector.id_mun === id_municipio) : sectores;
    }

    async GetOnlySector(id_sector: string) {
      var sectores = await this.GetSectores();
      return sectores.find(sector => sector.id_sector === id_sector);
    }


    // GetZonas(id_sector: string) {
    //   var sector = this.sectores.find(sector => sector.id_sector === id_sector);
    //   // console.log("SECTOR ---> ", sector)
    //   return this.zonas.filter(zonas => sector?.id_zona === zonas.id_zona);
    // }

    async GetUbicacionesSector(id_sector?: string) {
      var ubicacionesSector: API_UBICACION_SECTOR[] | undefined = this.ubicaciones_sector;

      if(!ubicacionesSector) {
        this.ubicaciones_sector = ubicacionesSector = await this.MasterGETDBFULL("ubicacion_sector");
      }

      return id_sector ? ubicacionesSector!.filter(u => u.id_sector === id_sector) : ubicacionesSector!;
    }

    async GetUbicaciones(id_sector?: string) {
      var ubicaciones: API_UBICACION[] | undefined = this.ubicaciones;
      var ubicaciones_Sector = await this.GetUbicacionesSector();

      if(!ubicaciones) {
        this.ubicaciones = ubicaciones = await this.MasterGETDBFULL("ubicacion");
      }

      return (id_sector ? ubicaciones!.filter(ubi => ubicaciones_Sector.some(sect => sect.id_ubi === ubi.id_ubi)) : ubicaciones)!;
    }

    async GetNomenclaturas(id_ubi?: string) {
      var nomenclaturas: API_NOMENCLATURA[] | undefined = this.nomenclaturas;
      var ubicaciones = await this.GetUbicaciones();

      if(!nomenclaturas) {
        this.nomenclaturas = nomenclaturas = await this.MasterGETDBFULL("nomenclatura");
      }

      return id_ubi ? nomenclaturas!.filter(nomen => ubicaciones.some(ubi => ubi.id_ubi === id_ubi && nomen.id_nomen === ubi.id_nomen)) : nomenclaturas!;
    }

    async GetOnlyUbicacion(id_ubicacion: string) {
      var ubicaciones = await this.GetUbicaciones();
      return ubicaciones.find(ubi => ubi.id_ubi === id_ubicacion);
    }

    async GetOnlyNomenclatura(id_nomenclatura: string) {
      var nomenclaturas = await this.GetNomenclaturas();
      return nomenclaturas.find(nomen => nomen.id_nomen === id_nomenclatura);
    }

    async GetOnlyUbicacionZona(id_ubicacion: string) {
      var ubi = await this.GetOnlyUbicacion(id_ubicacion);
      var nomen = await this.GetOnlyNomenclatura(ubi?.id_nomen || "");

      if(ubi && nomen) {
        var name = ubi.nombre_ubi;

        if(!name.includes(nomen.nombre_nomen) && !RegExp(`\\b${nomen.abrev_nomen}\\.?\\b`).test(name)) {
          name = nomen.nombre_nomen + " " + name;
        }

        return {
          nombre_ubicacion_zona: name,
          ...ubi,
          ...nomen,
        }
      }
    }

    async GetUbicacionesZonas(id_sector: string) {
      var sector = await this.GetOnlySector(id_sector);
      var ubicacionesSector = await this.GetUbicacionesSector(sector?.id_sector);

      var __ubicaciones = await this.GetUbicaciones();
      var __nomenclaturas = await this.GetNomenclaturas();

      var ubicaciones = (ubicacionesSector?.map(ubi => __ubicaciones.find(ubi2 => ubi.id_ubi == ubi2.id_ubi)) || []).filter(ubi => ubi !== undefined) as API_UBICACION[]
      var nomenclaturas = ubicaciones?.map(ubi => __nomenclaturas.find(nomen => nomen.id_nomen === ubi?.id_nomen)) as API_NOMENCLATURA[]

      // console.log("UBICACIONES-->", ubicaciones)
      // console.log("NOMENCLATURAS-->", nomenclaturas)
      
      var ubicacionesDisponibles: Array<API_UBICACION_ZONA> = [];

      for(let x = 0; x < ubicaciones.length && x < nomenclaturas.length; x++) {
        var name = ubicaciones[x].nombre_ubi;

        if(!name.includes(nomenclaturas[x].nombre_nomen) && !RegExp(`\\b${nomenclaturas[x].abrev_nomen}\\.?\\b`).test(name)) {
          name = nomenclaturas[x].nombre_nomen + " " + name;
        }

        ubicacionesDisponibles.push({
          nombre_ubicacion_zona: name,
          ...ubicaciones[x],
          ...nomenclaturas[x],
        })
      }

      return ubicacionesDisponibles;
    }

























  
  
    async MasterGETDBFULL(table: string, type: string = "find-all-info") {
      var apiUrl = DecryptService.decrypt(environment.dbfull, 16, 'default')
      
      const headers = {
        TokenAuthPlataform: `${environment.TokenAuthPlataform}`,
        db : environment.dbSae,
        table: `${DecryptService.encrypt(table)}`,
        type: `${DecryptService.encrypt(type)}`,
        Authorization: `${environment.authdbFUll}`
      }

      return (await axios.get(apiUrl, { headers })).data;
    }
}

export const UbicacionesService = new UbicacionesClass;